/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.snack-bar-success {
    background: #cdf8f1 !important;
    color: #234e52 !important;
}

.snack-bar-error {
    background: #f56565 !important;
}
.mat-simple-snackbar-action {
    color: #fff !important;
}

html {
    scroll-behavior: smooth;
}

.mat-tooltip {
    font-size: 12px !important;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;

    &-track {
        background: transparent;
        padding: 2px;
    }

    &-thumb {
        background: #8888;
        border-radius: 5px;

        &:hover {
            background: #888;
        }
    }
}
